@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Philosopher;
  src: url(./assets/Fonts/Philosopher-Regular.ttf);
}
@font-face {
  font-family: Devnagri;
  src: url(./assets/Fonts/Devnew.ttf);
}

.min-height {
  min-height: 80vh;
}
.carousel-status {
  display: none;
}